/* eslint-disable @typescript-eslint/no-unused-vars */
import { ClearOutlined, SearchOutlined } from '@mui/icons-material'
import {
  IconButton,
  InputAdornment,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { isEmpty } from 'lodash'
import { Ref, forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Key } from 'utils/keyCodes'

type Props = {
  onSearch: (search: string, reason?: 'EnterKeyPressed') => void
  timeout?: number
  disableTimeout?: boolean
  ref?: Ref<SearchTextFieldRef>
} & Omit<TextFieldProps, 'ref'>

export type SearchTextFieldRef = {
  clear: () => void
  getValue: () => string
}

const SearchTextField = (props: Props, ref: Ref<SearchTextFieldRef>) => {
  const {
    onSearch,
    timeout,
    disableTimeout,
    ref: textFieldRef,
    ...textFieldProps
  } = props
  const { t } = useTranslation()

  const [search, setSearch] = useState(props.defaultValue as string)

  const searchTimeout = useRef(undefined)

  useImperativeHandle(ref, () => ({
    clear: () => setSearch(''),
    getValue: () => {
      return search
    },
  }))

  const handleSearchChange = (value: string) => {
    if (props.disableTimeout) {
      setSearch(value)
      onSearch(isEmpty(value) ? undefined : value)
      return
    }

    clearTimeout(searchTimeout.current)

    setSearch(value)

    searchTimeout.current = setTimeout(() => {
      onSearch(isEmpty(value) ? undefined : value)
    }, props.timeout ?? 250)
  }

  return (
    <TextField
      {...textFieldProps}
      value={search}
      onKeyDown={(e) => {
        if (e.key === Key.Enter) {
          e.preventDefault()
          onSearch(isEmpty(search) ? undefined : search, 'EnterKeyPressed')
        }
      }}
      onChange={(e) => handleSearchChange(e.target.value)}
      placeholder={t('common:search', 'search')}
      InputProps={{
        ...textFieldProps.InputProps,
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              disabled={search === ''}
              onClick={() => handleSearchChange('')}
              size="small"
              color="secondary"
            >
              <ClearOutlined fontSize="small" color="secondary" />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  )
}

const SearchTextFieldWithRef = forwardRef<SearchTextFieldRef, Props>(
  SearchTextField
)

export default SearchTextFieldWithRef

// const MemoizedSearchField = React.memo(
//   forwardRef<SearchTextFieldRef, Props>(SearchTextField),
//   isEqual
// )

// export default MemoizedSearchField
